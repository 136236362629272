//
// TODO: Move this to a Page Rule
//
const Renew = () => {
  const isBrowser = typeof window !== 'undefined';
  if (isBrowser) {
    window.location.replace('https://www.smm.org/renew');
  }
  return null;
};

export default Renew;
